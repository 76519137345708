<template>
  <CForm ref="form" class="needs-validation"   >
    <CRow>
      <CCol>
        <CInput
            label="Sipariş Id: "
            horizontal
            type="number"
            v-model="data.orderId"
        />
      </CCol>
      <CCol>
        <CInput
            label="Code Prefix : "
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.codePrefix"
            :disabled="actionType=='update'"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CInput
            label="Planlanan Teslim Tarihi: "
            horizontal
            type="date"
            v-model="data.deliveryDatePlanned"
        />
      </CCol>
      <CCol>
          <CSelect
              placeholder="Status"
              :options="status"
              :value.sync="data.giftCardStatus"
              required
          />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CInput
            label="Alıcı Maili: "
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.recipientEmail"
            required
        />
      </CCol>
      <CCol>
        <CInput
            label="Alıcı Adı: "
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.recipientName"
            required
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CInput
            label="Gönderici Maili: "
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.senderEmail"
            required
        />
      </CCol>
      <CCol>
        <CInput
            label="Gönderici Adı: "
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.senderName"
            required
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CInput
            label="Toplam Limit: "
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.totalAmount"
            required
        />
      </CCol>
      <CCol>
        <CInput
            label="Harcanan Limit: "
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.spentAmount"
            requiered
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CInput
            label="Geçerlilik Başlangıcı: "
            horizontal
            type="date"
            autocomplete="text"
            v-model="data.validDateStart"
        />
      </CCol>
      <CCol>
        <CInput
            label="Geçerlilik Bitişi: "
            horizontal
            type="date"
            v-model="data.validDateEnd"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CInput
            label="Gönderici Notu: "
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.senderNote"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CSelect
            label="Adet"
            :options="options"
            type="text"
            horizontal
            :value.sync = "data.count"
            v-if="data.count !== undefined"
        />
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
export default{
  name: "GiftCardCrudForm",
  props:{
    validated: Boolean,
    params: Object,
    actionType: String,
  },
  watch:{
    params: function(val){
      this.data = {...val};
    },
    actionType:  function(val){
      if(val == 'create'){
        this.data = {...this.params};
      }
    },
  },
  data: function(){
    return{
      data: this.params ? {...this.params} : {},
      status: ['preparing', 'delivered', 'canceled'],
      options: [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
    }
  }
}
</script>